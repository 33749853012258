import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button , Text} from '@pancakeswap/uikit';
import { useWeb3React } from '@web3-react/core/core/core'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { ethers } from 'ethers';
import { useFarm1, useFarm10, useFarm19, useFarm2, useFarm23, useFarm25, useFarm29, useFarm3, useFarm30, useFarm4, useFarm5, useFarm6, useFarm7, useFarm8, useFarm9, useTotalSupplyFarm1, useTotalSupplyFarm10, useTotalSupplyFarm19, useTotalSupplyFarm2, useTotalSupplyFarm23, useTotalSupplyFarm25, useTotalSupplyFarm29, useTotalSupplyFarm3, useTotalSupplyFarm4, useTotalSupplyFarm5, useTotalSupplyFarm6, useTotalSupplyFarm7, useTotalSupplyFarm8, useTotalSupplyFarm9, useTotalSupplyFarmN } from 'hooks/useTokenBalance';
import { getBalanceNumber } from 'utils/formatBalance';
import { getbabyvizslaAddress, getbeethAddress, getBusdAddress, getbvcAddress, getCakeAddress, getHVIAddress, getLAMEAAddress, getlgcAddress, getWbnbAddress } from 'utils/addressHelpers';
import { usePriceCakeBusd, usePriceHVIBusd, usePricebeethBusd, usePriceLGCBusd, usePriceBABYVIZSLABusd, usePricebabyBusd, usePriceBnbBusd, usePriceBVCBusd, usePriceBTCBusd, usePriceETCBusd, usePriceETHBusd, usePriceLTCBusd, usePriceLameaBusd, usePriceSHIBBusd } from 'state/farms/hooks'; 
import FarmsPoolsRowLoader from '../Home/components/FarmsPoolsRowLoader';
import User1 from './User1';  
import User2 from './LpTokenPrice';   
  
const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 20px auto;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

const Title = styled(Text)`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 28px;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
`;

const TokenList = styled.div`
  display: flex;
  flex-direction: column;
`;

const TokenCard = styled.div<{ isSelected: boolean }>`
  margin: 10px 0;  
  padding: 15px;
  background: ${({ theme, isSelected }) => isSelected ? '#282c34' : theme.colors.background};
  border-radius: 12px;
  box-shadow: ${({ isSelected }) => isSelected ? '0 12px 24px rgba(0, 0, 0, 0.4)' : '0 6px 12px rgba(0, 0, 0, 0.2)'};
  display: flex;
  align-items: center;
  transition: transform 0.2s, box-shadow 0.2s, background 0.2s;
  &:hover {
    transform: scale(1.03);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
    background: #333;
  }
`;

const TokenImage = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 15px;
`;

const TokenDetails = styled.div`
  flex: 1;
`;

const TokenLabel = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  margin: 0;
`;

const TokenAddress = styled(Text)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.secondary};
  margin: 0;
`;

const TokenValue = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text};
  margin: 5px 0 0;
`;

const TotalValue = styled(Text)`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.text};
`;

const StyledButton = styled(Button)`
  margin: 20px 0;
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  color: ${({ theme }) => theme.colors.background};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 10px 20px;
  transition: background-color 0.2s, transform 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
    transform: scale(1.05);
  }
`;

const Arrow = styled.span<{ direction: 'up' | 'down' }>`
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-${({ direction }) => direction}: 6px solid ${({ theme }) => theme.colors.background};
  margin-left: 8px;
`;

const ERC20_ABI = [
  "function balanceOf(address owner) view returns (uint256)",
];

const TOKEN_ADDRESSES = {
  VIZSLASWAP: '0xaDAaE082237cB1772c9e079dB95c117E6Dd0C5aF',
  BVC: '0x2dEA12c4f08074032a9997F0a199b04aF0fC0392',
  USDT: '0x55d398326f99059ff775485246999027b3197955',
  LGC: '0x3496212eC43Cc49F5151ec4405efD4975e036f89',
  HVI: '0xDE619A9E0eEeAA9F8CD39522Ed788234837F3B26',
  BetHustlers: '0xE2B1f3908588e2338587ED998F974EE6F63f500d',
  BTC: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  ETC: '0x3d6545b08693daE087E957cb1180ee38B9e3c25E',
  ETH: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  LTC: '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94',
  LAMEA: '0xaa44051bdd76E251aab66dbbE82A97343B4D7DA3',
  BABYVIZSLA: '0xfB19F247F1f22Cef1F0384535C779c664632cB6F',
  SHIB: '0x2859e4544C4bB03966803b044A93563Bd2D0DD4D',
  BUSD: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  VIZSLASWAPBUSDLP: '0xC0f5FAAc9CF6bdE571aFAd1915eF183D8996CDb8', // pid20
  VIZSLASWAPBUSDLPPANCAKESWAP: '0x738156eAE2715776CBF92eD5751F7b8fc99951b7', // pid8
  VIZSLASWAPBNBLPPANCAKESWAP: '0x8d3cF6F026f1c8021BD3B0F74263D4D51208917F', // pid1
  VIZSLASWAPBNBLPAPESWAP: '0x51C1EbAFCCcF4A8e811E1fFE164731C874417AC3', // pid18
  HVIBNBLPPANCAKESWAP: '0x0388cc967e382A1c85161453bc6cEaA70E59E33e', // pid3
  HVIBNBLPAPESWAP: '0xF27312EeBD243dceb1856972deA340929a7Dbf91', // pid49
  HVIBUSDLP: '0x8F71caaB0e4A0eB8615aBfA6A12996cC3d37e988', // pid5
  BVCVIZSLASWAPLP: '0x189426C386C446Df3549e3B7Af9bF5D1321E30B9', // pid28
  BABYVIZSLAVIZSLASWAPLP: '0x58d30bA21b9Edb7c3526F4539319bEC3A7F23944', // pid29
  BABYVIZSLABNBLP: '0xeb94c1EA54AD6367D414B0B2075950646D0B6266', // pid6
  HVIVIZSLASWAPLP: '0x687351B70e02AAF7407D3a33f0032eC733C68738', // pid36
  BETHUSTLERSVIZSLASWAPLP: '0x0aFb79195368Ea93BF70F094193f1B3B4047F1DE', // pid50
  VIZSLASWAPLAMEALP: '0x6140a034d185a0a9bbb8afd4e3f92bcb0c69c67a', // BEP20
  HVILGCLP: '0xD41A8b2A8366AbdbF31FeDba8e5F6E13Ec959Ba5', // BEP20
  VIZSLASWAPLGCLP: '0x06979dF1cf9Af043EcEA997797071fA646AbaA57', // BEP20
};

const getTokenImageSrc = (tokenAddress: string) => {
  return `images/wallet/${tokenAddress}.png`; // Ensure the path and filename convention match your assets
};

const User: React.FC = () => {
  const [balances, setBalances] = useState<{ [key: string]: number }>({});
  const [values, setValues] = useState<{ [key: string]: number }>({});
  const [bnbBalance, setBnbBalance] = useState<number>(0);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [selectedToken, setSelectedToken] = useState<string | null>(null);
  const { account } = useWeb3React();
  const [provider, setProvider] = useState<ethers.providers.Web3Provider | null>(null);

  useEffect(() => {
    const initializeProviderAndContract = async () => {
      if (account) {
        try {
          const providerInstance = new ethers.providers.Web3Provider(window.ethereum);
          setProvider(providerInstance);
        } catch (error) {
          console.error('Failed to initialize provider:', error);
        }
      }
    };

    initializeProviderAndContract();
  }, [account]);
  // Fetch prices from hooks
  const priceCake = usePriceCakeBusd().toNumber();
  const priceBVC = usePriceBVCBusd().toNumber();
  const priceLGC = usePriceLGCBusd().toNumber();
  const priceHVI = usePriceHVIBusd().toNumber();
  const priceBnb = usePriceBnbBusd().toNumber();
  const priceBetHustlers = usePricebeethBusd().toNumber();
  const priceBTC = usePriceBTCBusd().toNumber();
  const priceETC = usePriceETCBusd().toNumber();
  const priceETH = usePriceETHBusd().toNumber();
  const priceLTC = usePriceLTCBusd().toNumber();
  const priceLamea = usePriceLameaBusd().toNumber();
  const priceBABYVIZSLA = usePriceBABYVIZSLABusd().toNumber();
  const priceSHIB = usePriceSHIBBusd().toNumber();
  const priceBUSD = 1; // BUSD price is 1 USD
  const priceWbnb = usePriceBnbBusd().toNumber();

  // prices for lp pid20
  const vizslalpbalance4 = getBalanceNumber(useFarm4(getCakeAddress()));
  const busdlpbalance2 = getBalanceNumber(useFarm4(getBusdAddress()));
  const kombo7 = useTotalSupplyFarm4();
  const kombo8 = kombo7 ? getBalanceNumber(kombo7) : 0;
  const teljes10 = priceCake * vizslalpbalance4;
  const teljes11 = 1 * busdlpbalance2;
  const teljes12 = teljes10 + teljes11;
  const price20pid = teljes12 / kombo8;

  // prices for lp pid1
  const vizslalpbalance = getBalanceNumber(useFarm1(getCakeAddress()));
  const bnblpbalance = getBalanceNumber(useFarm1(getWbnbAddress()));
  const kombo1 = useTotalSupplyFarm1();
  const kombo2 = kombo1 ? getBalanceNumber(kombo1) : 0;
  const teljes = priceCake * vizslalpbalance;
  const teljes2 = priceWbnb * bnblpbalance;
  const teljes3 = teljes + teljes2;
  const price1pid = teljes3 / kombo2;

  // price for lp pid18
  const vizslalpbalance2 = getBalanceNumber(useFarm2(getCakeAddress()));
  const bnblpbalance2 = getBalanceNumber(useFarm2(getWbnbAddress()));
  const kombo3 = useTotalSupplyFarm2();
  const kombo4 = kombo3 ? getBalanceNumber(kombo3) : 0;
  const teljes4 = priceCake * vizslalpbalance2;
  const teljes5 = priceWbnb * bnblpbalance2;
  const teljes6 = teljes4 + teljes5;
  const price18pid = teljes6 / kombo4;

  // price for lp pid8
  const vizslalpbalance3 = getBalanceNumber(useFarm3(getCakeAddress()));
  const busdlpbalance = getBalanceNumber(useFarm3(getBusdAddress()));
  const kombo5 = useTotalSupplyFarm3();
  const kombo6 = kombo5 ? getBalanceNumber(kombo5) : 0;
  const teljes7 = priceCake * vizslalpbalance3;
  const teljes8 = 1 * busdlpbalance;
  const teljes9 = teljes7 + teljes8;
  const price8pid = teljes9 / kombo6;

  // price for lp pid3
  const hvilpbalance = getBalanceNumber(useFarm5(getHVIAddress()));
  const bnblpbalance3 = getBalanceNumber(useFarm5(getWbnbAddress()));
  const kombo9 = useTotalSupplyFarm5();
  const kombo10 = kombo9 ? getBalanceNumber(kombo9) : 0;
  const teljes13 = priceHVI * hvilpbalance;
  const teljes14 = priceWbnb * bnblpbalance3;
  const teljes15 = teljes13 + teljes14;
  const price3pid = teljes15 / kombo10;

  // price for lp pid5
  const hvilpbalance2 = getBalanceNumber(useFarm6(getHVIAddress()));
  const busdlpbalance3 = getBalanceNumber(useFarm6(getBusdAddress()));
  const kombo11 = useTotalSupplyFarm6();
  const kombo12 = kombo11 ? getBalanceNumber(kombo11) : 0;
  const teljes16 = priceHVI * hvilpbalance2;
  const teljes17 = 1 * busdlpbalance3;
  const teljes18 = teljes16 + teljes17;
  const price5pid = teljes18 / kombo12;

  // price for lp pid6
  const bnblpbalance6 = getBalanceNumber(useFarm19(getWbnbAddress()));
  const babyvizslalpbalance = getBalanceNumber(useFarm19(getbabyvizslaAddress()));
  const kombo37 = useTotalSupplyFarm19();
  const kombo38 = kombo37 ? getBalanceNumber(kombo37) : 0;
  const teljes55 = priceBABYVIZSLA * babyvizslalpbalance;
  const teljes56 = priceWbnb * bnblpbalance6;
  const teljes57 = teljes55 + teljes56;
  const price6pid = teljes57 / kombo38;

  // price for lp pid50
  const vizslalpbalance22 = getBalanceNumber(useFarm29(getCakeAddress()));
  const beethbalance = getBalanceNumber(useFarm29(getbeethAddress()));
  const kombo55 = useTotalSupplyFarm29();
  const kombo56 = kombo55 ? getBalanceNumber(kombo55) : 0;
  const teljes82 = priceCake * vizslalpbalance22;
  const teljes83 = priceBetHustlers * beethbalance;
  const teljes84 = teljes82 + teljes83;
  const price50pid = teljes84 / kombo56;

  // price for lp pid36
  const hvilpbalance3 = getBalanceNumber(useFarm7(getHVIAddress()));
  const vizslalpbalance5 = getBalanceNumber(useFarm7(getCakeAddress()));
  const kombo13 = useTotalSupplyFarm7();
  const kombo14 = kombo13 ? getBalanceNumber(kombo13) : 0;
  const teljes19 = priceHVI * hvilpbalance3;
  const teljes20 = priceCake * vizslalpbalance5;
  const teljes21 = teljes19 + teljes20;
  const price36pid = teljes21 / kombo14;

  // price for lp pid29
  const babylpbalance = getBalanceNumber(useFarm10(getbabyvizslaAddress()));
  const vizslalpbalance8 = getBalanceNumber(useFarm10(getCakeAddress()));
  const kombo19 = useTotalSupplyFarm10();
  const kombo20 = kombo19 ? getBalanceNumber(kombo19) : 0;
  const teljes28 = priceBABYVIZSLA * babylpbalance;
  const teljes29 = priceCake * vizslalpbalance8;
  const teljes30 = teljes28 + teljes29;
  const price29pid = teljes30 / kombo20;

  // price for lp pid28
  const bvclpbalance = getBalanceNumber(useFarm9(getbvcAddress()));
  const vizslalpbalance7 = getBalanceNumber(useFarm9(getCakeAddress()));
  const kombo17 = useTotalSupplyFarm9();
  const kombo18 = kombo17 ? getBalanceNumber(kombo17) : 0;
  const teljes25 = priceBVC * bvclpbalance;
  const teljes26 = priceCake * vizslalpbalance7;
  const teljes27 = teljes25 + teljes26;
  const price28pid = teljes27 / kombo18;

  // price for lp pid49
  const hvipbalanceN = getBalanceNumber(useFarm30(getHVIAddress()));
  const bnbbalanceN = getBalanceNumber(useFarm30(getWbnbAddress()));
  const kombiN = useTotalSupplyFarmN();
  const kombiNN = kombiN ? getBalanceNumber(kombiN) : 0;
  const kombiNNN = priceHVI * hvipbalanceN;
  const kombiNNNN = priceWbnb * bnbbalanceN;
  const kombiNNNNN = kombiNNN + kombiNNNN;
  const kombiF = kombiNNNNN / kombiNN;

// bep20
  const lamealpbalance = getBalanceNumber(useFarm8(getLAMEAAddress()))
  const vizslalpbalance6 = getBalanceNumber(useFarm8(getCakeAddress()))
  const kombo15 = useTotalSupplyFarm8()
  const kombo16 = kombo15 ? getBalanceNumber(kombo15) : 0
  const teljes22 = priceLamea * lamealpbalance
  const teljes23 = priceCake * vizslalpbalance6
  const teljes24 = teljes22 + teljes23
  const minden1 = teljes24 / kombo16
 
  const vizslalpbalance19 = getBalanceNumber(useFarm23(getCakeAddress()))
  const lgclpbalance = getBalanceNumber(useFarm23(getlgcAddress()))
  const kombo45 = useTotalSupplyFarm23()
  const kombo46 = kombo45 ? getBalanceNumber(kombo45) : 0 
  const teljes67 = priceCake * vizslalpbalance19
  const teljes68 = priceLGC * lgclpbalance
  const teljes69 = teljes67 + teljes68
  const minden2 = teljes69 / kombo46

  const lgclp3 = getBalanceNumber(useFarm25(getlgcAddress()))
const hvilp4 = getBalanceNumber(useFarm25(getHVIAddress()))
const kombo49 = useTotalSupplyFarm25()
const kombo50 = kombo49 ? getBalanceNumber(kombo49) : 0
const teljes73 = priceLGC * lgclp3
const teljes74 = priceHVI * hvilp4
const teljes75 = teljes73 + teljes74
const minden4 = teljes75 / kombo50


  useEffect(() => {
    const fetchBalances = async () => {
      if (account) {
        try {
          // Fetch native BNB balance
          const bnbBalanceRaw = await provider.getBalance(account);
          const bnbBalanceFormatted = parseFloat(ethers.utils.formatUnits(bnbBalanceRaw, 18));
          setBnbBalance(bnbBalanceFormatted);

          // Fetch ERC20 token balances
          const balancePromises = Object.entries(TOKEN_ADDRESSES).map(async ([token, address]) => {
            const contract = new ethers.Contract(address, ERC20_ABI, provider);
            const balanceRaw = await contract.balanceOf(account);
            const decimals = token === 'HVI' ? 9 : 18;
            const balanceFormatted = parseFloat(ethers.utils.formatUnits(balanceRaw, decimals));
            return { token, address, balance: balanceFormatted };
          });

          const balancesArray = await Promise.all(balancePromises);
          const balancesObj = balancesArray.reduce((acc, { token, balance }) => ({
            ...acc,
            [token]: balance
          }), {});

          setBalances(balancesObj);
        } catch (error) { 
          console.error("Error fetching balances:", error);
        }
      }
    };

    fetchBalances();
  }, [account,provider]);

  useEffect(() => {
    const valuesObj = {
      VIZSLASWAP: balances.VIZSLASWAP ? balances.VIZSLASWAP * priceCake : 0,
      BVC: balances.BVC ? balances.BVC * priceBVC : 0,
      USDT: balances.USDT ? balances.USDT * 1 : 0,
      LGC: balances.LGC ? balances.LGC * priceLGC : 0,
      HVI: balances.HVI ? balances.HVI * priceHVI : 0,
      BNB: bnbBalance ? bnbBalance * priceBnb : 0,
      BetHustlers: balances.BetHustlers ? balances.BetHustlers * priceBetHustlers : 0,
      BTC: balances.BTC ? balances.BTC * priceBTC : 0,
      ETC: balances.ETC ? balances.ETC * priceETC : 0,
      ETH: balances.ETH ? balances.ETH * priceETH : 0,
      LTC: balances.LTC ? balances.LTC * priceLTC : 0,
      LAMEA: balances.LAMEA ? balances.LAMEA * priceLamea : 0,
      BABYVIZSLA: balances.BABYVIZSLA ? balances.BABYVIZSLA * priceBABYVIZSLA : 0,
      SHIB: balances.SHIB ? balances.SHIB * priceSHIB : 0,
      BUSD: balances.BUSD ? balances.BUSD * priceBUSD : 0,
      VIZSLASWAPBUSDLP: balances.VIZSLASWAPBUSDLP ? balances.VIZSLASWAPBUSDLP * price20pid : 0,
      VIZSLASWAPBUSDLPPANCAKESWAP: balances.VIZSLASWAPBUSDLPPANCAKESWAP ? balances.VIZSLASWAPBUSDLPPANCAKESWAP * price8pid : 0,
      VIZSLASWAPBNBLPPANCAKESWAP: balances.VIZSLASWAPBNBLPPANCAKESWAP ? balances.VIZSLASWAPBNBLPPANCAKESWAP * price1pid : 0,
      VIZSLASWAPBNBLPAPESWAP: balances.VIZSLASWAPBNBLPAPESWAP ? balances.VIZSLASWAPBNBLPAPESWAP * price18pid : 0,
      HVIBNBLPPANCAKESWAP: balances.HVIBNBLPPANCAKESWAP ? balances.HVIBNBLPPANCAKESWAP * price3pid : 0,
      HVIBNBLPAPESWAP: balances.HVIBNBLPAPESWAP ? balances.HVIBNBLPAPESWAP * kombiF : 0,
      HVIBUSDLP: balances.HVIBUSDLP ? balances.HVIBUSDLP * price5pid : 0,
      BVCVIZSLASWAPLP: balances.BVCVIZSLASWAPLP ? balances.BVCVIZSLASWAPLP * price28pid : 0,
      BABYVIZSLAVIZSLASWAPLP: balances.BABYVIZSLAVIZSLASWAPLP ? balances.BABYVIZSLAVIZSLASWAPLP * price29pid : 0,
      BABYVIZSLABNBLP: balances.BABYVIZSLABNBLP ? balances.BABYVIZSLABNBLP * price6pid : 0,
      HVIVIZSLASWAPLP: balances.HVIVIZSLASWAPLP ? balances.HVIVIZSLASWAPLP * price36pid : 0,
      BETHUSTLERSVIZSLASWAPLP: balances.BETHUSTLERSVIZSLASWAPLP ? balances.BETHUSTLERSVIZSLASWAPLP * price50pid : 0,
      VIZSLASWAPLAMEALP: balances.VIZSLASWAPLAMEALP ? balances.VIZSLASWAPLAMEALP * minden1 : 0,
      HVILGCLP: balances.HVILGCLP ? balances.HVILGCLP * minden4 : 0,
      VIZSLASWAPLGCLP: balances.VIZSLASWAPLGCLP ? balances.VIZSLASWAPLGCLP * minden2 : 0,
    };
    setValues(valuesObj);
  }, [balances, bnbBalance, priceCake, priceBVC, priceLGC, priceHVI, priceBnb, priceBetHustlers, priceBTC, priceETC, priceETH, priceLTC, priceLamea, priceBABYVIZSLA, priceSHIB, price20pid, price8pid, price1pid, price18pid, price3pid, kombiF, price5pid, price28pid, price29pid, price6pid, price36pid, price50pid, minden1, minden2, minden4]);

  const sortedTokens = Object.keys(values)
    .filter(token => (token === 'BNB' ? bnbBalance > 0 : balances[token] > 0))
    .sort((a, b) => values[b] - values[a]);

  const totalValue = Object.values(values).reduce((acc, value) => acc + value, 0);

  return (
    <>
    <Container>
      <FarmsPoolsRowLoader />
      <Title>Token Balance Checker</Title>
      {account ? (
              <StyledButton onClick={() => setShowDetails(!showDetails)}>
              {showDetails ? 'Hide Details ▲' : 'Show Details ▼'}
              <Arrow direction={showDetails ? 'up' : 'down'} />
            </StyledButton>
          ) : (
   
              <ConnectWalletButton />
          )}
      {showDetails && (
        <>
          <TotalValue>Total Value: ${totalValue.toFixed(2)}</TotalValue>
          <TokenList>
            {sortedTokens.map(token => (
              <TokenCard
                key={token}
                isSelected={selectedToken === token}
                onClick={() => setSelectedToken(selectedToken === token ? null : token)}
              >
                <TokenImage src={getTokenImageSrc(TOKEN_ADDRESSES[token])} alt={token} />
                <TokenDetails>
                  <TokenLabel>{token}</TokenLabel>
                  <TokenAddress>{TOKEN_ADDRESSES[token]}</TokenAddress>
                  <TokenValue>{balances[token] ? balances[token].toFixed(2) : '0.00'} (${values[token] ? values[token].toFixed(2) : '0.00'})</TokenValue>
                </TokenDetails>
              </TokenCard>
            ))}
          </TokenList>
        </>
      )}
    </Container>  <User1 /> <User2 /></>

  );
};

 
export default User;
