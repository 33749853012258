import React, { useEffect, useState, useCallback } from 'react';
import { createClient } from '@supabase/supabase-js';
import { useWeb3React } from '@web3-react/core/core/core';
import { Link } from 'react-router-dom'; // Import Link for navigation
import {
  Container,
  UserListContainer,
  UserItem,
  UserAvatar, 
  UserName, 
  OnlineStatus,
  SearchInput, // Add this styled component for search input
  HeaderContainer, // Container for the header
  BackButton, // Styled component for back button
  Title, // Styled component for the title
} from './StyledComponents'; // Assume these styled components exist

// Initialize Supabase client
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

interface UserData {
  account: string;
  name: string;
  number: number | null; // Number can be null
  onlineStatus: boolean; // CamelCase for consistency
}

const UserList: React.FC = () => {
  const { account } = useWeb3React(); // Getting account from Web3React
  const [userData, setUserData] = useState<UserData[]>([]); // State for user data
  const [filteredUsers, setFilteredUsers] = useState<UserData[]>([]); // State for filtered users
  const [searchTerm, setSearchTerm] = useState(''); // Search term state
  const [userOnlineStatus, setUserOnlineStatus] = useState<boolean>(false); // State for the current user's online status
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state

  // Fetch user data from Supabase
  const fetchUserData = useCallback(async () => {
    setLoading(true); // Set loading to true while fetching
    try {
      const { data, error } = await supabase
        .from('user_data')
        .select('account, name, number, online_status'); // Use correct column name "online_status"

      if (error) {
        setError(`Error fetching data: ${error.message}`);
        return;
      }

      setUserData(data || []); // Set user data or an empty array
      setFilteredUsers(data || []); // Set filtered users initially to all users
    } catch (err) {
      setError('Unexpected error occurred while fetching data');
    } finally {
      setLoading(false); // Set loading to false after fetch
    }
  }, []);

  // Handle search input changes
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    // Filter users by name
    const filtered = userData.filter(user => 
      user.name?.toLowerCase().includes(searchTerm) || 
      user.account.toLowerCase().includes(searchTerm)
    );
    setFilteredUsers(filtered);
  };

  // Function to get user image, with fallback to 200.png if no number is provided
  const getUserImage = (userNumber: number | null) => {
    if (userNumber === null) {
      return 'images/pixel/200.png'; // Default image if no number
    }
    const formattedNumber = userNumber < 10 ? `0${userNumber}` : userNumber.toString();
    return `images/pixel/${formattedNumber}.jpg`; // Assume this path exists
  };

  // Fetch user data on mount
  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  if (loading) {
    return <div>Loading users...</div>; // Display loading message
  }

  if (error) {
    return <div>Error: {error}</div>; // Display error message
  }

  // Sort the users: online users at the top, then alphabetically
  const sortedUsers = [...filteredUsers].sort((a, b) => {
    if (a.online_status === b.online_status) {
      return (a.name || a.account).localeCompare(b.name || b.account);
    }
    return a.online_status ? -1 : 1; // Online users at the top
  });

  return (
    <Container>
      {/* Header with Back Button and Title */}
      <HeaderContainer>
      <Link to="/privatechat">
        <BackButton onClick={() => {/* handle navigation logic here */}}>
          &lt; Back to PrivateChat
        </BackButton>
        </Link>
       </HeaderContainer>

      {/* Search Input */}
      <SearchInput 
        type="text"
        placeholder="Search by name or account"
        value={searchTerm}
        onChange={handleSearchChange}
      />

      {/* User List Display */}
      <UserListContainer>
        {sortedUsers.map((user) => (
          <UserItem key={user.account}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <UserAvatar
                src={getUserImage(user.number)}
                alt={`${user.name || user.account}'s avatar`}
              />
              <UserName>
                {user.name ||
                  `${user.account.substring(0, 5)}...${user.account.substring(
                    user.account.length - 5
                  )}`}
              </UserName>
            </div>
            <OnlineStatus isOnline={user.online_status}>
              {user.online_status ? 'Online' : 'Offline'}
            </OnlineStatus>
          </UserItem>
        ))}
      </UserListContainer>
    </Container>
  );
};

export default UserList;
