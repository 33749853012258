import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Button, Text } from '@pancakeswap/uikit';
import { useWeb3React } from '@web3-react/core/core/core'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { ethers } from 'ethers';
import { useFarm1, useFarm10, useFarm19, useFarm2, useFarm29, useFarm3, useFarm30, useFarm4, useFarm5, useFarm6, useFarm7, useFarm9, useTotalSupplyFarm1, useTotalSupplyFarm10, useTotalSupplyFarm19, useTotalSupplyFarm2, useTotalSupplyFarm29, useTotalSupplyFarm3, useTotalSupplyFarm4, useTotalSupplyFarm5, useTotalSupplyFarm6, useTotalSupplyFarm7, useTotalSupplyFarm9, useTotalSupplyFarmN } from 'hooks/useTokenBalance';
import { getBalanceNumber } from 'utils/formatBalance';
import { getbabyvizslaAddress, getbeethAddress, getBusdAddress, getbvcAddress, getCakeAddress, getHVIAddress, getWbnbAddress } from 'utils/addressHelpers';
import { usePriceCakeBusd, usePriceHVIBusd, usePricebeethBusd, usePriceLGCBusd, usePriceBABYVIZSLABusd, usePricebabyBusd, usePriceBnbBusd, usePriceBVCBusd } from 'state/farms/hooks'; 
import { Container, Title, TokenList,StyledButton,Arrow, TokenCard, TokenImage, TokenDetails, TokenLabel, TokenAddress, TokenValue, TotalValue} from './Styled';
import FarmsPoolsRowLoader from '../Home/components/FarmsPoolsRowLoader';
import STAKING_CONTRACT_ABI_WITH_PID from './Abi.json';
import STAKING_CONTRACT_ABI from './Abi2.json';

const VIZSLASWAP_STAKING_CONTRACT_ADDRESSES = [
  '0xf142BD28CaCd766c24201bD2FF4513CEc032e882',
  '0xd4B328Ee8a6801ac9e93175C6dB55b70B08aef5E',
  '0x44c44e691e523378fec15Ba101AE0618fCA21cC4',
  '0x6688B8f6D2c0aEdeADf8c84d2aCDC87C2E1f02A9',
  '0xa05E95D44d746FC833041fCb62103A04850e1851',
  '0x96e41f4e0F885121756b84fe966C39ED92047968',
  '0x37031eD3a1adf0C4C72d3EB6832fDc3D1af42d2a',
  '0x1F125E10C83024D40B50077D76Cc119f63eF967A',
  '0x47cE6b2Ec96D1576Da6D1B714C6ffd455E31132a',
  '0x215BeF6E6dDF19ACE123610EC8b4B49E9d8A68BA',
  '0x7F4ddf4F41bf7306D76bC2DDf46f286DBfBC5c4C',
  '0x3804FcF2F09163a3459780298F1Cc6d7ee44165D',
  '0x4fC86dC3E0B03971B4d08990843c754055C8c3D9',
  '0x495Cd5Fc0dF972A028AD50c7be23d2350702B228',
  '0xebcF793c2cF3aeD607Fd64e71ac6BEdAAf7F915A',
];

const HVI_STAKING_CONTRACT_ADDRESSES = [
  '0x2BCB14C24F95E1D6978e1d9dcA5A1a01F4a68D88',
  '0x78D1d79449da238bA76FB47CAff92De04C468622',
  '0x0340fdb81618874936535CA5276294fB84A719Ee',
  '0xe78Bf25A181A9b60Aef08a9F5e2079CB745234cb',
  '0x7027A5F70a93fDB1A6488f49db6f3e543E4881B7',
  '0xC79EDA00d5EC26320693eaEE7b09987457CAeB50',
  '0xD97462E7CD68C5e408cAf745F43C06Bc02bd4BC4',
  '0xD24bffa1a9e3632A10CF07037aCf34bFe468Ff30',
];

const BET_HUSTLERS_STAKING_CONTRACT_ADDRESSES = [
  '0x7E7E1eD77ed403488d631758a83fcCd86a0aa787',
];

const LGC_STAKING_CONTRACT_ADDRESSES = [
  '0x74339CB10Ff9E68918d2e4E23a4597e1046e89cA',
];

const BABY_VIZSLA_STAKING_CONTRACT_ADDRESSES = [
  '0x646086C73D560B7a4581F6229738c88733C92e4B',
];

const SYRUP_STAKING_CONTRACT_ADDRESSES = [
  '0x1Cb956fb2A963084BBa550a59De6B4a897973534',
];

const ERC20_ABI = [
  "function balanceOf(address owner) view returns (uint256)",
];

const TOKEN_ADDRESSES = {
  VIZSLASWAP: '0xaDAaE082237cB1772c9e079dB95c117E6Dd0C5aF',
  BVC: '0x2dEA12c4f08074032a9997F0a199b04aF0fC0392',
  USDT: '0x55d398326f99059ff775485246999027b3197955',
  LGC: '0x3496212eC43Cc49F5151ec4405efD4975e036f89',
  HVI: '0xDE619A9E0eEeAA9F8CD39522Ed788234837F3B26',
  BetHustlers: '0xE2B1f3908588e2338587ED998F974EE6F63f500d',
  BTC: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  ETC: '0x3d6545b08693daE087E957cb1180ee38B9e3c25E',
  ETH: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  LTC: '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94',
  LAMEA: '0xaa44051bdd76E251aab66dbbE82A97343B4D7DA3',
  BABYVIZSLA: '0xfB19F247F1f22Cef1F0384535C779c664632cB6F',
  SHIB: '0x2859e4544C4bB03966803b044A93563Bd2D0DD4D',
  BUSD: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  AUTOVIZSLASWAP: '0xf8a4ce3ec23dc726fcb9127f75075e62e615a242',
  MANUALVIZSLASWAP: '0xaDAaE082237cB1772c9e079dB95c117E6Dd0C5aF', 
  VIZSLASWAPBUSDLP: '0xC0f5FAAc9CF6bdE571aFAd1915eF183D8996CDb8', // pid20
  VIZSLASWAPBUSDLPPANCAKESWAP: '0x738156eAE2715776CBF92eD5751F7b8fc99951b7', // pid8
  VIZSLASWAPBNBLPPANCAKESWAP: '0x8d3cF6F026f1c8021BD3B0F74263D4D51208917F', // pid1
  VIZSLASWAPBNBLPAPESWAP: '0x51C1EbAFCCcF4A8e811E1fFE164731C874417AC3', // pid18
  HVIBNBLPPANCAKESWAP: '0x0388cc967e382A1c85161453bc6cEaA70E59E33e', // pid3
  HVIBNBLPAPESWAP: '0xF27312EeBD243dceb1856972deA340929a7Dbf91', // pid49
  HVIBUSDLP: '0x8F71caaB0e4A0eB8615aBfA6A12996cC3d37e988', // pid5
  BVCVIZSLASWAPLP: '0x189426C386C446Df3549e3B7Af9bF5D1321E30B9', // pid28
  BABYVIZSLAVIZSLASWAPLP: '0x58d30bA21b9Edb7c3526F4539319bEC3A7F23944', // pid29
  BABYVIZSLABNBLP: '0xeb94c1EA54AD6367D414B0B2075950646D0B6266', // pid6
  HVIVIZSLASWAPLP: '0x687351B70e02AAF7407D3a33f0032eC733C68738', // pid36
  BETHUSTLERSVIZSLASWAPLP: '0x0aFb79195368Ea93BF70F094193f1B3B4047F1DE', // pid50
};

const getTokenImageSrc = (tokenAddress: string) => {
  return `images/wallet/${tokenAddress}.png`; // Ensure the path and filename convention match your assets
};

const User1: React.FC = () => {
  const [balances, setBalances] = useState<{ [key: string]: number }>({});
  const { account } = useWeb3React()
  const [values, setValues] = useState<{ [key: string]: number }>({});
  const [bnbBalance, setBnbBalance] = useState<number>(0);
  const [provider, setProvider] = useState<ethers.providers.Web3Provider | null>(null);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [selectedToken, setSelectedToken] = useState<string | null>(null);
  const [stakedBalance, setStakedBalance] = useState<number>(0);
  const [hviStakedBalance, setHviStakedBalance] = useState<number>(0);
  const [lgcStakedBalance, setLgcStakedBalance] = useState<number>(0);
  const [babyVizslaStakedBalance, setBabyVizslaStakedBalance] = useState<number>(0);
  const [beethStakedBalance, setBeethStakedBalance] = useState<number>(0);
  const [syrupStakedBalance, setSyrupStakedBalance] = useState<number>(0);
  const [notAutoVizslaSwapBalance, setNotAutoVizslaSwapBalance] = useState<number>(0);
  const [vizslaswapbusdlpBalance, setvizslaswapbusdlp] = useState<number>(0);
  const [vizslaswapbusdlppancakeswapBalance, setvizslaswapbusdlppancakeswap] = useState<number>(0);
  const [vizslaswapbnblppancakeswapBalance, setvizslaswapbnblppanacakeswap] = useState<number>(0);
  const [vizslaswapbnblpapeswapBalance, setvizslaswapbnblpapeswap] = useState<number>(0);
  const [hvibnblppancakeswapBalance, sethvibnblppancakeswap] = useState<number>(0);
  const [hvibnblpapeswapBalance, sethvibnblpapeswap] = useState<number>(0);
  const [hvibusdlpBalance, sethvibusdlp] = useState<number>(0);
  const [bvcvizslaswaplpBalance, setbvcvizslaswaplp] = useState<number>(0);
  const [babyvizslavizslaswaplpBalance, setbabyvizslavizslaswaplp] = useState<number>(0);
  const [babyvizslabnblpBalance, setbabyvizslabnblp] = useState<number>(0);
  const [hvivizslaswaplpBalance, sethvivizslaswaplp] = useState<number>(0);
  const [bethustlersvizslaswaplpBalance, setbethustlervizslaswaplp] = useState<number>(0);
  useEffect(() => {
    if (account) {
      try {
        const providerInstance = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(providerInstance);
      } catch (error) {
        console.error('Failed to initialize provider:', error);
      }
    }
  }, [account]);
  // Fetch prices from hooks
  const priceCake = usePriceCakeBusd().toNumber();
  const priceHvi = usePriceHVIBusd().toNumber(); 
  const priceLgc = usePriceLGCBusd().toNumber();
  const priceBeeth = usePricebeethBusd().toNumber();
  const priceBabyVizsla = usePriceBABYVIZSLABusd().toNumber();
  const priceSyrup = usePriceCakeBusd().toNumber();
  const priceWbnb = usePriceBnbBusd().toNumber();
  const pricebvc = usePriceBVCBusd().toNumber();

// prices for lp pid20
const vizslalpbalance4 = getBalanceNumber(useFarm4(getCakeAddress()))
const busdlpbalance2 = getBalanceNumber(useFarm4(getBusdAddress()))
const kombo7 = useTotalSupplyFarm4()
const kombo8 = kombo7 ? getBalanceNumber(kombo7) : 0
const teljes10 = priceCake * vizslalpbalance4
const teljes11 = 1 * busdlpbalance2
const teljes12 = teljes10 + teljes11
const price20pid = teljes12 / kombo8
// prices for lp pid1
const vizslalpbalance = getBalanceNumber(useFarm1(getCakeAddress()))
const bnblpbalance = getBalanceNumber(useFarm1(getWbnbAddress()))
const kombo1 = useTotalSupplyFarm1()
const kombo2 = kombo1 ? getBalanceNumber(kombo1) : 0
const teljes = priceCake * vizslalpbalance 
const teljes2 = priceWbnb * bnblpbalance
const teljes3 = teljes + teljes2
const price1pid = teljes3 / kombo2  
// price for lp pid18
const vizslalpbalance2 = getBalanceNumber(useFarm2(getCakeAddress()))
const bnblpbalance2 = getBalanceNumber(useFarm2(getWbnbAddress()))
const kombo3 = useTotalSupplyFarm2()
const kombo4 = kombo3 ? getBalanceNumber(kombo3) : 0
const teljes4 = priceCake * vizslalpbalance2 
const teljes5 = priceWbnb * bnblpbalance2
const teljes6 = teljes4 + teljes5
const price18pid = teljes6 / kombo4
// price for lp pid8
const vizslalpbalance3 = getBalanceNumber(useFarm3(getCakeAddress()))
const busdlpbalance = getBalanceNumber(useFarm3(getBusdAddress()))
const kombo5 = useTotalSupplyFarm3()
const kombo6 = kombo5 ? getBalanceNumber(kombo5) : 0
const teljes7 = priceCake * vizslalpbalance3 
const teljes8 = 1 * busdlpbalance
const teljes9 = teljes7 + teljes8
const price8pid = teljes9 / kombo6
// price for lp pid3
const hvilpbalance = getBalanceNumber(useFarm5(getHVIAddress()))
const bnblpbalance3 = getBalanceNumber(useFarm5(getWbnbAddress()))
const kombo9 = useTotalSupplyFarm5()
const kombo10 = kombo9 ? getBalanceNumber(kombo9) : 0
const teljes13 = priceHvi * hvilpbalance
const teljes14 = priceWbnb * bnblpbalance3
const teljes15 = teljes13 + teljes14
const price3pid = teljes15 / kombo10
// price for lp pid5
const hvilpbalance2 = getBalanceNumber(useFarm6(getHVIAddress()))
      const busdlpbalance3 = getBalanceNumber(useFarm6(getBusdAddress()))
      const kombo11 = useTotalSupplyFarm6()
      const kombo12 = kombo11 ? getBalanceNumber(kombo11) : 0
      const teljes16 = priceHvi * hvilpbalance2
      const teljes17 = 1 * busdlpbalance3
      const teljes18 = teljes16 + teljes17
      const price5pid = teljes18 / kombo12
// price for lp pid6
const bnblpbalance6 = getBalanceNumber(useFarm19(getWbnbAddress()))
const babyvizslalpbalance = getBalanceNumber(useFarm19(getbabyvizslaAddress()))

const kombo37 = useTotalSupplyFarm19()
const kombo38 = kombo37 ? getBalanceNumber(kombo37) : 0

const teljes55 = priceBabyVizsla * babyvizslalpbalance
const teljes56 = priceWbnb * bnblpbalance6
const teljes57 = teljes55 + teljes56
const price6pid = teljes57 / kombo38
// price for lp pid50
const vizslalpbalance22 = getBalanceNumber(useFarm29(getCakeAddress()))
const beethbalance = getBalanceNumber(useFarm29(getbeethAddress()))
const kombo55 = useTotalSupplyFarm29()
const kombo56 = kombo55 ? getBalanceNumber(kombo55) : 0
const teljes82 = priceCake * vizslalpbalance22
const teljes83 = priceBeeth * beethbalance
const teljes84 = teljes82 + teljes83
const price50pid = teljes84 / kombo56
// price for lp pid36
const hvilpbalance3 = getBalanceNumber(useFarm7(getHVIAddress()))
const vizslalpbalance5 = getBalanceNumber(useFarm7(getCakeAddress()))

const kombo13 = useTotalSupplyFarm7()
const kombo14 = kombo13 ? getBalanceNumber(kombo13) : 0

const teljes19 = priceHvi * hvilpbalance3
const teljes20 = priceCake * vizslalpbalance5
const teljes21 = teljes19 + teljes20
const price36pid = teljes21 / kombo14
// price for lp pid29
const babylpbalance = getBalanceNumber(useFarm10(getbabyvizslaAddress()))
  const vizslalpbalance8 = getBalanceNumber(useFarm10(getCakeAddress()))
  const kombo19 = useTotalSupplyFarm10()
  const kombo20 = kombo19 ? getBalanceNumber(kombo19) : 0
  const teljes28 = priceBabyVizsla * babylpbalance
  const teljes29 = priceCake * vizslalpbalance8
  const teljes30 = teljes28 + teljes29
  const price29pid = teljes30 / kombo20
// price for lp pid28
const bvclpbalance = getBalanceNumber(useFarm9(getbvcAddress()))
  const vizslalpbalance7 = getBalanceNumber(useFarm9(getCakeAddress()))
  
  const kombo17 = useTotalSupplyFarm9()
  const kombo18 = kombo17 ? getBalanceNumber(kombo17) : 0
  
  const teljes25 = pricebvc * bvclpbalance
  const teljes26 = priceCake * vizslalpbalance7
  const teljes27 = teljes25 + teljes26
  const price28pid = teljes27 / kombo18
// price for lp pid49
const hvipbalanceN = getBalanceNumber(useFarm30(getHVIAddress()))
const bnbbalanceN = getBalanceNumber(useFarm30(getWbnbAddress()))
const kombiN = useTotalSupplyFarmN()
const kombiNN = kombiN ? getBalanceNumber (kombiN) : 0
const kombiNNN = priceHvi * hvipbalanceN
const kombiNNNN = priceWbnb * bnbbalanceN
const kombiNNNNN = kombiNNN + kombiNNNN
const kombiF = kombiNNNNN / kombiNN

useEffect(() => {
  if (account && document.visibilityState === 'visible') {
    try {
      const providerInstance = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(providerInstance);
    } catch (error) {
      console.error('Failed to initialize provider:', error);
    }
  }
}, [account]);

const fetchStakedBalance = useCallback(async () => {
  if (account && document.visibilityState === 'visible' && showDetails) {
    try {
      const provider = new ethers.providers.Web3Provider((window as any).ethereum);

      const vizslaswapBalancePromises = VIZSLASWAP_STAKING_CONTRACT_ADDRESSES.map(async (contractAddress) => {
        const contract = new ethers.Contract(contractAddress, STAKING_CONTRACT_ABI, provider);
        const { amount } = await contract.userInfo(account);
        return parseFloat(ethers.utils.formatUnits(amount, 18)); 
      }); 

      const vizslaswapStakedBalances = await Promise.all(vizslaswapBalancePromises);
      const totalStakedBalance = vizslaswapStakedBalances.reduce((acc, balance) => acc + balance, 0);

      const hviBalancePromises = HVI_STAKING_CONTRACT_ADDRESSES.map(async (contractAddress) => {
        const contract = new ethers.Contract(contractAddress, STAKING_CONTRACT_ABI, provider);
        const { amount } = await contract.userInfo(account);
        return parseFloat(ethers.utils.formatUnits(amount, 9)); 
      });

      const hviStakedBalances = await Promise.all(hviBalancePromises);
      const hviStakedBalance = hviStakedBalances.reduce((acc, balance) => acc + balance, 0);

      const lgcBalancePromises = LGC_STAKING_CONTRACT_ADDRESSES.map(async (contractAddress) => {
        const contract = new ethers.Contract(contractAddress, STAKING_CONTRACT_ABI, provider);
        const { amount } = await contract.userInfo(account);
        return parseFloat(ethers.utils.formatUnits(amount, 18)); 
      });

      const lgcStakedBalances = await Promise.all(lgcBalancePromises);
      const lgcStakedBalance = lgcStakedBalances.reduce((acc, balance) => acc + balance, 0);

      const babyVizslaBalancePromises = BABY_VIZSLA_STAKING_CONTRACT_ADDRESSES.map(async (contractAddress) => {
        const contract = new ethers.Contract(contractAddress, STAKING_CONTRACT_ABI, provider);
        const { amount } = await contract.userInfo(account);
        return parseFloat(ethers.utils.formatUnits(amount, 18)); 
      });

      const babyVizslaStakedBalances = await Promise.all(babyVizslaBalancePromises);
      const babyVizslaStakedBalance = babyVizslaStakedBalances.reduce((acc, balance) => acc + balance, 0);

      const beethBalancePromises = BET_HUSTLERS_STAKING_CONTRACT_ADDRESSES.map(async (contractAddress) => {
        const contract = new ethers.Contract(contractAddress, STAKING_CONTRACT_ABI, provider);
        const { amount } = await contract.userInfo(account);
        return parseFloat(ethers.utils.formatUnits(amount, 18)); 
      });

      const beethStakedBalances = await Promise.all(beethBalancePromises);
      const beethStakedBalance = beethStakedBalances.reduce((acc, balance) => acc + balance, 0);

      const syrupBalancePromises = SYRUP_STAKING_CONTRACT_ADDRESSES.map(async (contractAddress) => {
        const contract = new ethers.Contract(contractAddress, STAKING_CONTRACT_ABI, provider);
        const { cakeAtLastUserAction } = await contract.userInfo(account);
        return parseFloat(ethers.utils.formatUnits(cakeAtLastUserAction, 18)); 
      });

      const syrupStakedBalances = await Promise.all(syrupBalancePromises);
      const syrupStakedBalance = syrupStakedBalances.reduce((acc, balance) => acc + balance, 0);

      const notAutoVizslaSwapContractAddress = '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1';
      const notAutoVizslaSwapContract = new ethers.Contract(notAutoVizslaSwapContractAddress, STAKING_CONTRACT_ABI_WITH_PID, provider);
      const { amount: notAutoVizslaSwapAmount } = await notAutoVizslaSwapContract.userInfo(0, account);
      const notAutoVizslaSwapBalance = parseFloat(ethers.utils.formatUnits(notAutoVizslaSwapAmount, 18)); 
      
      const vizslaswapbusdlpAddress = '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1';
      const vizslaswapbusdlpContract = new ethers.Contract(vizslaswapbusdlpAddress, STAKING_CONTRACT_ABI_WITH_PID, provider);
      const { amount: vizslaswapbusdlpAmount } = await vizslaswapbusdlpContract.userInfo(20, account);
      const vizslaswapbusdlpBalance = parseFloat(ethers.utils.formatUnits(vizslaswapbusdlpAmount, 18)); 

      const vizslaswapbusdlppancakeswapAddress = '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1';
      const vizslaswapbusdlppancakeswapContract = new ethers.Contract(vizslaswapbusdlppancakeswapAddress, STAKING_CONTRACT_ABI_WITH_PID, provider);
      const { amount: vizslaswapbusdlppancakeswapAmount } = await vizslaswapbusdlppancakeswapContract.userInfo(8, account);
      const vizslaswapbusdlppancakeswapBalance = parseFloat(ethers.utils.formatUnits(vizslaswapbusdlppancakeswapAmount, 18)); 
      
      const vizslaswapbnblppancakeswapAddress = '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1';
      const vizslaswapbnblppancakeswapContract = new ethers.Contract(vizslaswapbnblppancakeswapAddress, STAKING_CONTRACT_ABI_WITH_PID, provider);
      const { amount: vizslaswapbnblppancakeswapAmount } = await vizslaswapbnblppancakeswapContract.userInfo(1, account);
      const vizslaswapbnblppancakeswapBalance = parseFloat(ethers.utils.formatUnits(vizslaswapbnblppancakeswapAmount, 18)); 

      const vizslaswapbnblpapeswapAddress = '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1';
      const vizslaswapbnblpapeswapContract = new ethers.Contract(vizslaswapbnblpapeswapAddress, STAKING_CONTRACT_ABI_WITH_PID, provider);
      const { amount: vizslaswapbnblpapeswapAmount } = await vizslaswapbnblpapeswapContract.userInfo(18, account);
      const vizslaswapbnblpapeswapBalance = parseFloat(ethers.utils.formatUnits(vizslaswapbnblpapeswapAmount, 18)); 

      const hvibnblppancakeswapAddress = '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1';
      const hvibnblppancakeswapContract = new ethers.Contract(hvibnblppancakeswapAddress, STAKING_CONTRACT_ABI_WITH_PID, provider);
      const { amount: hvibnblppancakeswapAmount } = await hvibnblppancakeswapContract.userInfo(3, account);
      const hvibnblppancakeswapBalance = parseFloat(ethers.utils.formatUnits(hvibnblppancakeswapAmount, 18)); 

      const hvibnblpapeswapAddress = '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1';
      const hvibnblpapeswapContract = new ethers.Contract(hvibnblpapeswapAddress, STAKING_CONTRACT_ABI_WITH_PID, provider);
      const { amount: hvibnblpapeswapAmount } = await hvibnblpapeswapContract.userInfo(49, account);
      const hvibnblpapeswapBalance = parseFloat(ethers.utils.formatUnits(hvibnblpapeswapAmount, 18)); 

      const hvibusdlpAddress = '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1';
      const hvibusdlpContract = new ethers.Contract(hvibusdlpAddress, STAKING_CONTRACT_ABI_WITH_PID, provider);
      const { amount: hvibusdlpAmount } = await hvibusdlpContract.userInfo(5, account);
      const hvibusdlpBalance = parseFloat(ethers.utils.formatUnits(hvibusdlpAmount, 18)); 

      const bvcvizslaswaplpAddress = '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1';
      const bvcvizslaswaplpContract = new ethers.Contract(bvcvizslaswaplpAddress, STAKING_CONTRACT_ABI_WITH_PID, provider);
      const { amount: bvcvizslaswaplpAmount } = await bvcvizslaswaplpContract.userInfo(28, account);
      const bvcvizslaswaplpBalance = parseFloat(ethers.utils.formatUnits(bvcvizslaswaplpAmount, 18)); 

      const babyvizslavizslaswaplpAddress = '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1';
      const babyvizslavizslaswaplpContract = new ethers.Contract(babyvizslavizslaswaplpAddress, STAKING_CONTRACT_ABI_WITH_PID, provider);
      const { amount: babyvizslavizslaswaplpAmount } = await babyvizslavizslaswaplpContract.userInfo(29, account);
      const babyvizslavizslaswaplpBalance = parseFloat(ethers.utils.formatUnits(babyvizslavizslaswaplpAmount, 18)); 

      const babyvizslabnblpAddress = '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1';
      const babyvizslabnblpContract = new ethers.Contract(babyvizslabnblpAddress, STAKING_CONTRACT_ABI_WITH_PID, provider);
      const { amount: babyvizslabnblpAmount } = await babyvizslabnblpContract.userInfo(6, account);
      const babyvizslabnblpBalance = parseFloat(ethers.utils.formatUnits(babyvizslabnblpAmount, 18)); 

      const hvivizslaswapblpAddress = '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1';
      const hvivizslaswapblpContract = new ethers.Contract(hvivizslaswapblpAddress, STAKING_CONTRACT_ABI_WITH_PID, provider);
      const { amount: hvivizslaswapblpAmount } = await hvivizslaswapblpContract.userInfo(36, account);
      const hvivizslaswaplpBalance = parseFloat(ethers.utils.formatUnits(hvivizslaswapblpAmount, 18)); 

      const bethustlersvizslaswaplpAddress = '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1';
      const bethustlersvizslaswaplpContract = new ethers.Contract(bethustlersvizslaswaplpAddress, STAKING_CONTRACT_ABI_WITH_PID, provider);
      const { amount: bethustlersvizslaswaplpAmount } = await bethustlersvizslaswaplpContract.userInfo(50, account);
      const bethustlersvizslaswaplpBalance = parseFloat(ethers.utils.formatUnits(bethustlersvizslaswaplpAmount, 18)); 

      setStakedBalance(totalStakedBalance);
      setHviStakedBalance(hviStakedBalance);
      setLgcStakedBalance(lgcStakedBalance);
      setBabyVizslaStakedBalance(babyVizslaStakedBalance);
      setBeethStakedBalance(beethStakedBalance);
      setSyrupStakedBalance(syrupStakedBalance);
      setNotAutoVizslaSwapBalance(notAutoVizslaSwapBalance);
      setvizslaswapbusdlp(vizslaswapbusdlpBalance);
      setvizslaswapbusdlppancakeswap(vizslaswapbusdlppancakeswapBalance);
      setvizslaswapbnblppanacakeswap(vizslaswapbnblppancakeswapBalance);
      setvizslaswapbnblpapeswap(vizslaswapbnblpapeswapBalance);
      sethvibnblppancakeswap(hvibnblppancakeswapBalance);
      sethvibnblpapeswap(hvibnblpapeswapBalance);
      sethvibusdlp(hvibusdlpBalance);
      setbvcvizslaswaplp(bvcvizslaswaplpBalance);
      setbabyvizslavizslaswaplp(babyvizslavizslaswaplpBalance);
      setbabyvizslabnblp(babyvizslabnblpBalance);
      sethvivizslaswaplp(hvivizslaswaplpBalance);
      setbethustlervizslaswaplp(bethustlersvizslaswaplpBalance);

    } catch (error) {
      console.error("Error fetching staked balance:", error);
    }
  }
}, [account, showDetails]);

useEffect(() => {
  const fetchBalances = async () => {
    if (account && document.visibilityState === 'visible' && showDetails) {
      try {
        const bnbBalanceRaw = await provider.getBalance(account);
        const bnbBalanceFormatted = parseFloat(ethers.utils.formatUnits(bnbBalanceRaw, 18));
        setBnbBalance(bnbBalanceFormatted);

        const balancePromises = Object.entries(TOKEN_ADDRESSES).map(async ([token, address]) => {
          const contract = new ethers.Contract(address, ERC20_ABI, provider);
          const balanceRaw = await contract.balanceOf(account);
          const decimals = token === 'HVI' || token === 'LGC' || token === 'BABYVIZSLA' || token === 'BetHustlers' ? 18 : 18;
          const balanceFormatted = parseFloat(ethers.utils.formatUnits(balanceRaw, decimals));
          return { token, address, balance: balanceFormatted };
        });

        const balancesArray = await Promise.all(balancePromises);
        const balancesObj = balancesArray.reduce((acc, { token, balance }) => ({
          ...acc,
          [token]: balance
        }), {});

        setBalances(balancesObj);
      } catch (error) {
        console.error("Error fetching balances:", error);
      }
    }
  };

  fetchBalances();
  fetchStakedBalance();
}, [account, fetchStakedBalance, showDetails, provider]);

useEffect(() => {
  const valuesObj = {
    VIZSLASWAP: stakedBalance * priceCake,
    HVI: hviStakedBalance * priceHvi,
    LGC: lgcStakedBalance * priceLgc,
    BABYVIZSLA: babyVizslaStakedBalance * priceBabyVizsla,
    BetHustlers: beethStakedBalance * priceBeeth,
    AUTOVIZSLASWAP: syrupStakedBalance * priceSyrup,
    MANUALVIZSLASWAP: notAutoVizslaSwapBalance * priceCake ,
    VIZSLASWAPBUSDLP: vizslaswapbusdlpBalance * price20pid, // pid20,
    VIZSLASWAPBUSDLPPANCAKESWAP: vizslaswapbusdlppancakeswapBalance * price8pid ,// pid8
    VIZSLASWAPBNBLPPANCAKESWAP: vizslaswapbnblppancakeswapBalance * price1pid, // pid1
    VIZSLASWAPBNBLPAPESWAP: vizslaswapbnblpapeswapBalance * price18pid ,// pid18
    HVIBNBLPPANCAKESWAP: hvibnblppancakeswapBalance * price3pid ,// pid3
    HVIBNBLPAPESWAP: hvibnblpapeswapBalance * kombiF, // pid49,
    HVIBUSDLP: hvibusdlpBalance * price5pid ,// pid5
    BVCVIZSLASWAPLP: bvcvizslaswaplpBalance * price28pid ,// pid28
    BABYVIZSLAVIZSLASWAPLP: babyvizslavizslaswaplpBalance * price29pid ,// pid29
    BABYVIZSLABNBLP: babyvizslabnblpBalance * price6pid, // pid6
    HVIVIZSLASWAPLP: hvivizslaswaplpBalance * price36pid ,// pid36
    BETHUSTLERSVIZSLASWAPLP: bethustlersvizslaswaplpBalance * price50pid // pid50

  };
  setValues(valuesObj);
}, [stakedBalance, hviStakedBalance, lgcStakedBalance, babyVizslaStakedBalance, beethStakedBalance, syrupStakedBalance, notAutoVizslaSwapBalance, vizslaswapbusdlpBalance, vizslaswapbusdlppancakeswapBalance, vizslaswapbnblppancakeswapBalance, vizslaswapbnblpapeswapBalance, hvibnblppancakeswapBalance, hvibnblpapeswapBalance, hvibusdlpBalance, bvcvizslaswaplpBalance, babyvizslavizslaswaplpBalance, babyvizslabnblpBalance, bethustlersvizslaswaplpBalance, priceCake, priceHvi, priceLgc, priceBabyVizsla, hvivizslaswaplpBalance, priceBeeth, priceSyrup, price20pid , price8pid, price1pid, price18pid, price3pid, kombiF, price5pid, price28pid, price29pid, price6pid, price36pid, price50pid]);

const sortedTokens = Object.keys(values)
    .filter(token => values[token] > 0) // Only include tokens with a value greater than 0
  .sort((a, b) => values[b] - values[a]); // Sort by USD value descending

const totalValue = Object.values(values).reduce((acc, value) => acc + value, 0);

return (
  <Container>
    <FarmsPoolsRowLoader />
    <Title>Staked Balance Checker</Title>
    {account ? (
              <StyledButton onClick={() => setShowDetails(!showDetails)}>
              {showDetails ? 'Hide Details ▲' : 'Show Details ▼'}
              <Arrow direction={showDetails ? 'up' : 'down'} />
            </StyledButton>
          ) : (
   
              <ConnectWalletButton />
          )}
  
    {showDetails && (
      <>
        <TotalValue>Total Value: ${totalValue.toFixed(2)}</TotalValue>
        <TokenList>
          {sortedTokens.map(token => (
            <TokenCard
              key={token}
              isSelected={selectedToken === token}
              onClick={() => setSelectedToken(selectedToken === token ? null : token)}
            >
              <TokenImage src={getTokenImageSrc(TOKEN_ADDRESSES[token])} alt={token} />
              <TokenDetails>
                <TokenLabel>{token}</TokenLabel>
                <TokenAddress>{TOKEN_ADDRESSES[token]}</TokenAddress>
                <TokenValue>
                  {token === 'HVI' ? hviStakedBalance.toFixed(2) : 
                   token === 'LGC' ? lgcStakedBalance.toFixed(2) :
                   token === 'BABYVIZSLA' ? babyVizslaStakedBalance.toFixed(2) :
                   token === 'BetHustlers' ? beethStakedBalance.toFixed(2) :
                   token === 'AUTOVIZSLASWAP' ? syrupStakedBalance.toFixed(2) :
                   token === 'MANUALVIZSLASWAP' ? notAutoVizslaSwapBalance.toFixed(2) :
                   token === 'VIZSLASWAPBUSDLP' ? vizslaswapbusdlpBalance.toFixed(2) :
                   token === 'VIZSLASWAPBUSDLPPANCAKESWAP' ? vizslaswapbusdlppancakeswapBalance.toFixed(2) :
                   token === 'VIZSLASWAPBNBLPPANCAKESWAP' ? vizslaswapbnblppancakeswapBalance.toFixed(2) :
                   token === 'VIZSLASWAPBNBLPAPESWAP' ? vizslaswapbnblpapeswapBalance.toFixed(2) :
                   token === 'HVIBNBLPPANCAKESWAP' ? hvibnblppancakeswapBalance.toFixed(2) :
                   token === 'HVIBNBLPAPESWAP' ? hvibnblpapeswapBalance.toFixed(2) :
                   token === 'HVIBUSDLP' ? hvibusdlpBalance.toFixed(2) :
                   token === 'BVCVIZSLASWAPLP' ? bvcvizslaswaplpBalance.toFixed(2) :
                   token === 'BABYVIZSLAVIZSLASWAPLP' ? babyvizslavizslaswaplpBalance.toFixed(2) :
                   token === 'BABYVIZSLABNBLP' ? babyvizslabnblpBalance.toFixed(2) :
                   token === 'HVIVIZSLASWAPLP' ? hvivizslaswaplpBalance.toFixed(2) :
                   token === 'BETHUSTLERSVIZSLASWAPLP' ? bethustlersvizslaswaplpBalance.toFixed(2) :
                  (stakedBalance).toFixed(2)} 
                  {' '} 
                  (${values[token] ? values[token].toFixed(2) : '0.00'})
                </TokenValue>
              </TokenDetails>
            </TokenCard>
          ))}
        </TokenList>
      </>
    )}
  </Container>
  );
};

export default User1;
