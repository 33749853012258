import { Button,Box,Text } from "@pancakeswap/uikit";
import styled, { keyframes } from "styled-components";

const runningLightAnimation = keyframes`
  0% {
    text-shadow: 0 0 2.5px #62d5a3, 0 0 5px #62d5a3, 0 0 7.5px #62d5a3, 0 0 10px #62d5a3;
  }
  50% {
    text-shadow: 0 0 5px #62d5a3, 0 0 7.5px #62d5a3, 0 0 10px #62d5a3, 0 0 12.5px #62d5a3;
  }
  100% {
    text-shadow: 0 0 2.5px #62d5a3, 0 0 5px #62d5a3, 0 0 7.5px #62d5a3, 0 0 10px #62d5a3;
  }
`;
const hoverEffect = keyframes`
  0% {
    transform: translateX(0);
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
  }
  100% {
    transform: translateX(0);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.7);
  }
`;
const activeEffect = keyframes`
  0% {
    transform: translateX(0);
    box-shadow: 0 0 5px rgba(83, 178, 140, 0.3);
  }
  100% {
    transform: translateX(0);
    box-shadow: 0 0 15px rgba(83, 178, 140, 0.7);
  }
`;

const Title = styled(Text)`
  text-align: center;
  color: #fff;
  font-size: 50px;
  font-weight: bold; /* Make the text thicker */
  margin-bottom: 20px;
    margin-top: 40px;
    animation: ${runningLightAnimation} 2s infinite; /* Alkalmazzuk az animációt */
`;

const ChatBox = styled(Box)`
  flex: 1;
  overflow-y: auto;
  max-height: 300px;
      min-height: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #000;
  color: #fff;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    width: 100%;
  max-height: 300px;
      min-height: 300px;

  }
`;

const InputWrapper = styled.div`
  display: flex; // Flexbox használata, hogy az elemek egymás mellé kerüljenek
  align-items: center; // Vertikálisan középre igazítja az elemeket
  width: 100%;
`;

interface EnterTextProps {
  show: boolean;
}
const EnterText = styled.button<EnterTextProps>`
  background-color: transparent; // Átlátszó belső tér
  color: gold; // Arany szöveg
  border: 2px solid gold; // Arany szegély
  font-size: 12px;
  font-weight: bold;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')}; 
  border-radius: 10px; // Lekerekített sarkok
  height: 30px; 
  width: 50px;
  cursor: pointer; // Mutató kurzor a gomb felett
  display: flex;
  align-items: center;
  justify-content: center; // Középre igazítja a szöveget a gombon belül
  &:hover {
    background-color: gold; // Hover állapotban arany háttér
    color: white; // A szöveg fehér lesz hover állapotban
  }
`;


const Container = styled.div`
  display: flex;
  flex-direction: row; 
  align-items: flex-start;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 50px;
  max-height: 580px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #000;
`;

const Sidebar = styled.div`
  width: 100%;
  padding: 10px;
 min-height: 560px;
    max-height: 560px;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
    border-right: none;
      min-height: 560px;
    max-height: 560px;
  }
`;

const UserList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 100%;
  overflow-y: auto;
`;

interface UserListItemProps {
  isActive: boolean;
  isTop10: boolean;
}

const UserListItem = styled.li<UserListItemProps>`
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
  color: ${({ isTop10 }) => (isTop10 ? '#d4bf79' : '#fff')};

  &:hover {
    background-color: #555;
  }

  &:active {
    background-color: #fff;
  }

  ${({ isActive }) => isActive && `
    background-color: #555;
  `}
`;

const UserButton = styled.button`
  display: flex;
  align-items: center; // Vertically centers content
  cursor: pointer;
  background: none;
  justify-content: flex-start; // Horizontally aligns content to the left
  border: none;
  height: 60px; // Sets a fixed height
  padding: 0;
  font-weight: bold;
  color: inherit;
  text-align: left;
  width: 100%; // Full width of the container
`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 25%;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  background-color: #000;
  color: #fff;
`;

const Message = styled(Text)`
  flex: 1;
`;

const Input = styled.input<{ error?: boolean; fontSize?: string; align?: string; isActive?: boolean }>`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #000;
  margin-bottom: 10px;
  width: 100%;
 -webkit-appearance: textfield;
  transition: all 0.3s ease;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.primary};
  }

  animation: ${({ isActive }) => (isActive ? activeEffect : hoverEffect)} 0.5s forwards;

  &:hover {
    animation: ${({ isActive }) => (isActive ? activeEffect : hoverEffect)} 0.5s forwards;
  }

  &:focus {
    animation: ${activeEffect} 0.5s forwards;
    background-color: ${({ theme }) => theme.colors.background};
  }
`;

const SendButton = styled(Button)`
  width: 100%;
  transition: transform 0.2s;
`;

const Timestamp = styled.span`
  display: block;
  font-size: 0.8em;
  color: #aaa;
`;

export { Container, ChatBox, Sidebar,UserList, UserListItem, UserButton,EnterText, InputWrapper, Avatar, MessageContainer, Message, Input, SendButton, Timestamp, Title };
