import styled from 'styled-components';
import { Text } from '@pancakeswap/uikit'; // Import PancakeSwap's Button component

export const Container = styled.div`
  padding: 20px;
`;

export const UserListContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: black;
  gap: 8px; /* Reduced gap for a more compact look */
`;

export const UserItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px; /* Smaller padding */
  border: 1px solid #ccc;
  border-radius: 6px; /* Smaller radius */
  background-color: #000;
  font-size: 14px; /* Reduced font size */
`;

export const UserAvatar = styled.img`
  width: 35px; /* Smaller avatar */
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
`;
 
export const UserName = styled(Text)`
  font-size: 14px; /* Smaller font size */
  font-weight: bold;
  color: white;
  text-align: center; /* Center the text horizontally */
  margin-right: 10px; /* Space between name and status */
`;

export const OnlineStatus = styled.span<{ isOnline: boolean }>`
  font-size: 15px; /* Smaller font for status */
  color: ${({ isOnline }) => (isOnline ? 'green' : 'red')};
  font-weight: bold;
`;

// New components

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;
`;

export const BackButton = styled.button`
  background-color: #ffcc00;
  color: black;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #ff9900;
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  color: white;
  margin-left: 20px;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-bottom: 20px; 
    &:hover {
    transform: scale(0.9); /* Scale down on hover */
  }
`;
